import { useEffect, useState } from 'react';
import { gatekeeper } from '../gatekeeper';

export type TResolvedLoggedInState = {
  isLoggedIn: boolean;
  casumoName?: string;
  accessToken?: string;
  name?: string;
  playerId?: string;
  sessionId?: string;
};

type TResolveKeys = {
  [K in keyof TResolvedLoggedInState]?: boolean;
};

export const useLoggedInState = (keysToResolve: TResolveKeys): TResolvedLoggedInState | null => {
  const [resolvedLoggedInState, setResolvedLoggedInState] = useState<TResolvedLoggedInState | null>(
    null
  );

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const resolveLoggedInState = async () => {
      const resolvedState = {} as TResolvedLoggedInState;

      try {
        if (keysToResolve.isLoggedIn) {
          resolvedState.isLoggedIn = await gatekeeper.auth?.isLoggedIn();
        }

        if (keysToResolve.casumoName) {
          resolvedState.casumoName = await gatekeeper.auth?.getCasumoName();
        }

        if (keysToResolve.name) {
          resolvedState.name = await gatekeeper.auth?.getName();
        }

        if (keysToResolve.sessionId) {
          resolvedState.sessionId = await gatekeeper.auth?.getSessionId();
        }

        if (keysToResolve.playerId) {
          resolvedState.playerId = await gatekeeper.auth?.getPlayerId();
        }

        if (keysToResolve.accessToken) {
          resolvedState.playerId = await gatekeeper.auth?.getAccessToken();
        }
      } catch (e) {
        console.error(e);
      } finally {
        setResolvedLoggedInState(resolvedState);
        setIsLoading(false);
      }
    };

    resolveLoggedInState();
  }, []);

  if (isLoading) {
    return null;
  }

  return resolvedLoggedInState;
};
