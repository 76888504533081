import { useMediaQuery } from 'usehooks-ts';
import { screens } from '@casumo/fabric-fundamentals';

const screensExtended = {
  ...screens,
  'lg-desktop': '1440px',
  'xl-desktop': '1920px',
};

export const useResponsiveScreen = () => {
  const isMobile = useMediaQuery(`(max-width: ${screensExtended.phablet})`);
  const isPhablet = useMediaQuery(
    `(min-width: ${screensExtended.phablet}) and (max-width: ${screensExtended.desktop})`
  );
  const isUnder768 = useMediaQuery(`(max-width: ${screensExtended.tablet})`);
  const isTablet = useMediaQuery(
    `(min-width: ${screensExtended.tablet}) and (max-width: ${screensExtended.desktop})`
  );
  const isDesktop = useMediaQuery(
    `(min-width: ${screensExtended.desktop}) and (max-width: ${screensExtended['lg-desktop']})`
  );
  const isLargeDesktop = useMediaQuery(
    `(min-width: ${screensExtended['lg-desktop']}) and (max-width: ${screensExtended['xl-desktop']})`
  );
  const isXLargeDesktop = useMediaQuery(`(min-width: ${screensExtended['xl-desktop']})`);

  return {
    isMobile,
    isPhablet,
    isUnder768,
    isTablet,
    isMobileOrPhablet: isMobile || isPhablet,
    isDesktop,
    isLargeDesktop,
    isXLargeDesktop,
  };
};
