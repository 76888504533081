import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { useSelector } from 'react-redux';
import { type TSetCustomEventRequest } from './fasttrackRealtimeIntegration.types';
import { selectPlayerId } from 'Store';

const API_URL = '/casino-player/fasttrack-realtime-integration/api/v1';

export const FastTrackRealTimeIntegrationApi = createApi({
  reducerPath: 'fastTrackRealTimeIntegration',
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
  }),
  endpoints: (builder) => ({
    getMarketingBlocks: builder.query<null, string>({
      keepUnusedDataFor: 1,
      query: (playerId) => `/marketingBlocks/${playerId}`,
    }),
    setCustomEvent: builder.mutation<void, TSetCustomEventRequest>({
      query: (body) => ({
        url: '/custom-event',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useGetMarketingBlocksQuery, useSetCustomEventMutation } =
  FastTrackRealTimeIntegrationApi;

// 404 means no blocks
export function useGetPlayerFTMarketingBlocks() {
  const playerId = useSelector(selectPlayerId) as string;

  return useGetMarketingBlocksQuery(playerId);
}
