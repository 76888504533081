import { gatekeeper } from 'Src/gatekeeper';
import { type TCurrency } from 'Src/types';
import { getIsValidCurrency } from 'Utils';
import { useState, useEffect } from 'react';

export const usePlayerCurrency = () => {
  const [currency, setCurrency] = useState<TCurrency | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    let abort = false;
    (async function () {
      try {
        if (!abort) setIsLoading(true);
        const currency = await gatekeeper.wallet.getPlayerCurrency();
        const isValidCurrency = getIsValidCurrency(currency);
        if (!abort && isValidCurrency) setCurrency(currency as TCurrency);
      } finally {
        if (!abort) setIsLoading(false);
      }
      return () => {
        abort = true;
      };
    })();
  }, [setIsLoading, setCurrency]);
  return { currency, isLoading };
};

export const usePlayerId = () => {
  const [playerId, setPlayerId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    let abort = false;
    (async function () {
      try {
        if (!abort) setIsLoading(true);
        const playerId = await gatekeeper.auth.getPlayerId();
        const isValidId = Boolean(playerId);
        if (!abort && isValidId) setPlayerId(playerId as string);
      } finally {
        if (!abort) setIsLoading(false);
      }
      return () => {
        abort = true;
      };
    })();
  }, [setIsLoading, setPlayerId]);
  return { playerId, isLoading };
};

export const useCasumoName = () => {
  const [casumoName, setCasumoName] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    let abort = false;
    (async function () {
      try {
        if (!abort) setIsLoading(true);
        const casumoName = await gatekeeper.auth.getCasumoName();
        if (!abort) setCasumoName(casumoName ?? '');
      } finally {
        if (!abort) setIsLoading(false);
      }
      return () => {
        abort = true;
      };
    })();
  }, [setIsLoading, setCasumoName]);
  return { casumoName, isLoading };
};
