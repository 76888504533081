import { useEffect, useRef, useState } from 'react';

export const useSize0Detector = (): [
  React.RefObject<HTMLDivElement>,
  { widthIs0: boolean; heightIs0: boolean }
] => {
  const ref = useRef<HTMLDivElement>(null);
  const [heightIs0, setHeightIs0] = useState(true);
  const [widthIs0, setWidthIs0] = useState(true);
  useEffect(() => {
    // TODO: update usehooks-ts and replace the following code with useResizeObserver
    const observer = new ResizeObserver((entries) => {
      const width = entries?.[0].contentBoxSize?.[0]?.inlineSize;
      const height = entries?.[0].contentBoxSize?.[0]?.blockSize;
      setWidthIs0(width < 1);
      setHeightIs0(height < 1);
    });
    if (ref?.current) {
      observer.observe(ref?.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [setWidthIs0, setHeightIs0]);
  return [ref, { widthIs0, heightIs0 }];
};
