import { log } from 'Utils';
import { useEffect } from 'react';
import { ErrorFallback } from './ErrorFallback';
import { type TWebComponent } from 'Components/types';
import { needVisibleFeedback } from './ErrorFallback.constants';

type TProps = {
  error: Error;
  webComponent: TWebComponent;
};

export function ErrorFallbackContainer({ error, webComponent }: TProps) {
  useEffect(() => {
    log({
      level: 'critical',
      error,
    });
  }, [error]);

  if (!needVisibleFeedback.includes(webComponent)) {
    return null;
  }

  return <ErrorFallback />;
}
