import React from 'react';
import type { RefObject } from 'react';

type TUseHorizontalScroll = {
  scrollContainerRef: RefObject<HTMLDivElement>;
  handleScroll: () => void;
  scrollTo: (shift: number) => void;
  isAtStart: boolean;
  isAtEnd: boolean;
};

export const useHorizontalScroll = (): TUseHorizontalScroll => {
  const scrollContainerRef = React.useRef<HTMLDivElement>(null);
  const [isAtStart, setIsAtStart] = React.useState<boolean>(true);
  const [isAtEnd, setIsAtEnd] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!scrollContainerRef.current) {
      return;
    }

    setIsAtEnd(scrollContainerRef.current.scrollWidth === scrollContainerRef.current.offsetWidth);
  }, [scrollContainerRef?.current?.scrollWidth, scrollContainerRef?.current?.offsetWidth]);

  const handleScroll = () => {
    if (!scrollContainerRef.current) {
      return;
    }

    setIsAtStart(scrollContainerRef.current?.scrollLeft === 0);
    setIsAtEnd(
      Math.floor(
        scrollContainerRef.current?.scrollWidth - scrollContainerRef.current?.scrollLeft
      ) <= scrollContainerRef.current?.offsetWidth
    );
  };

  const scrollTo = (shift: number) => {
    scrollContainerRef.current?.scrollTo({
      left: scrollContainerRef.current?.scrollLeft + shift,
      behavior: 'smooth',
    });
  };

  return {
    scrollContainerRef,
    handleScroll,
    scrollTo,
    isAtStart,
    isAtEnd,
  };
};
