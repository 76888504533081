import { PlayerApi } from '../player/playerApi';
import { NOTIFICATION_STATUS } from './notifications.constants';
import { type TPendingNotification } from './notifications.types';

const API_BASE_URL = '/persistent-notifications/api/v1/notifications';
const API_URL_GET_TOAST_NOTIFICATIONS = `${API_BASE_URL}?status=UNREAD&notificationType=TOAST&page=0&limit=100`;

export const NotificationsApi = PlayerApi.injectEndpoints({
  endpoints: (builder) => ({
    getToastNotifications: builder.query<TPendingNotification[], void>({
      query: () => ({
        method: 'GET',
        url: API_URL_GET_TOAST_NOTIFICATIONS,
      }),
    }),
    markToastNotificationAsRead: builder.mutation<void, { id: string }>({
      query: (notification) => ({
        url: `${API_BASE_URL}/${notification.id}/state`,
        method: 'PUT',
        body: {
          notificationStatus: NOTIFICATION_STATUS.READ,
        },
      }),
    }),
  }),
  overrideExisting: true,
});

export const { useLazyGetToastNotificationsQuery, useMarkToastNotificationAsReadMutation } =
  NotificationsApi;

export const GET_TOAST_QUERY = `
  query getToast($marketConfigId: ID!, $toastName: String!){
      market_config_by_id(id: $marketConfigId) {
          toasts(
              filter: {
                  toasts_id: {
                      name: {_eq: $toastName}
                  }
              }
          ) {
              toasts_id {
                  name
                  kind
                  priority
                  autoclose_duration
                  translations {
                      languages_code { code }
                      title
                      message
                      primary_button_text
                      secondary_button_text
                      thumbnail_image {
                          id
                      }
                  }
              }
          }
      }
  }
    `;
