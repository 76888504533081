import { useDispatch } from 'react-redux';
import { type TRootState, type TToast, useAppSelector, addToast, removeToast } from 'Store';

export const useToast = () => {
  const dispatch = useDispatch();
  const toasts = useAppSelector((state: TRootState) => state.toast.toasts);

  const addItem = (toast: TToast) => {
    dispatch(addToast(toast));
  };

  const removeItem = (id: string) => {
    dispatch(removeToast(id));
  };

  return { addItem, removeItem, toasts };
};
