/* eslint-disable @typescript-eslint/explicit-function-return-type */
// ^^ Missing some return types for the web component-related functions

// @ts-expect-error: We know we're a script
const host = new URL(document.currentScript?.src).origin;

export type TOnConnection = (
  root: ShadowRoot,
  attributes: Record<string, unknown>,
  host: string,
  onReady: () => void
) => void;

export type TOnDisconnection<T> = (node: TRootNode<T> | null) => void;

export type TRootNode<T> = ShadowRoot & { applicationNode?: T };

export function createWebComponent<T>(
  name: string,
  onConnection: TOnConnection,
  onDisconnection: TOnDisconnection<T>,
  isShadowDomOpen?: boolean
) {
  customElements.define(
    name,
    class extends HTMLElement {
      mounted: boolean;
      host: ShadowRoot | null;
      constructor() {
        super();
        this.mounted = false;
        this.host = null;
      }

      connectedCallback() {
        const attributes = this.getAttributeNames().reduce(
          (obj, name) => ({
            ...obj,
            [name]: this.getAttribute(name),
          }),
          {}
        );

        if (!this.mounted) {
          this.mounted = true;
          this.host = this.attachShadow({ mode: isShadowDomOpen ? 'open' : 'closed' });

          onConnection(
            this.host,
            attributes,
            host,
            () => this.dispatchEvent(new Event('micro-frontend-attached')) // https://casumo.atlassian.net/browse/PBOB-295
          );
        }
      }

      disconnectedCallback() {
        this.mounted = false;
        onDisconnection(this.host);
      }
    }
  );
}

export async function createStylesLink(node: ShadowRoot, href: string) {
  await new Promise((resolve, reject) => {
    const styles = document.createElement('link');

    styles.href = href;
    styles.type = 'text/css';
    styles.rel = 'stylesheet';
    styles.onload = resolve;
    styles.onerror = reject;

    node.appendChild(styles);
  });
}

export async function createStyles(node: ShadowRoot, cssText: string) {
  await new Promise((resolve) => {
    const styles = document.createElement('style');

    styles.textContent = cssText;

    node.appendChild(styles);

    resolve(undefined);
  });
}

export async function createApplicationContainer(node: ShadowRoot) {
  const container = document.createElement('main');

  node.appendChild(container);

  return container;
}
