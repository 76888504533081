import { useCallback, useState } from 'react';

export const useSelectedPopUpId = (): [
  string,
  boolean,
  (id?: string | null) => void,
  () => void
] => {
  const [id, setId] = useState('');
  const setSelectedId = useCallback(
    (id?: string | null) => {
      if (id === null || id === undefined) return;
      setId(id);
    },
    [setId]
  );
  const closePopUp = useCallback(() => {
    setId('');
  }, [setId]);

  const isOpen = Boolean(id);

  return [id, isOpen, setSelectedId, closePopUp];
};
