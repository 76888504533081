import { useSelector } from 'react-redux';
import { selectPlayerId } from 'Store';
import { jurisdictionCheck } from 'Utils';
import { useGetExclusionStatusQuery } from 'Services/marketingExclusion';

export function useGetEsMarketingExclusionStatus() {
  const playerId = useSelector(selectPlayerId) as string;
  const { isDGOJ } = jurisdictionCheck();

  const { data, isLoading } = useGetExclusionStatusQuery(
    { language: 'es', playerId },
    {
      pollingInterval: 18e5,
      skip: !isDGOJ,
    }
  );

  return { marketingExclusionStatus: data, isLoading };
}
