import { PlayerApi } from '../player/playerApi';
import { type TGetExclusionStatusRequest } from './marketingExclusion.types';

const API_URL_RG_MARKETING_EXCLUSIONS = '/casino-player/rg-marketing-exclusions/api/v1/player';
export const marketingExclusionApi = PlayerApi.injectEndpoints({
  endpoints: (builder) => ({
    getExclusionStatus: builder.query<boolean, TGetExclusionStatusRequest>({
      query: ({ language, playerId }) => ({
        headers: {
          'Content-Type': 'application/json',
          'X-Player-Id': playerId,
        },
        url: `${API_URL_RG_MARKETING_EXCLUSIONS}/${language}/excluded`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetExclusionStatusQuery } = marketingExclusionApi;
