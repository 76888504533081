import React, { createContext, useContext } from 'react';
import {
  createDirectus,
  type GqlResult as GqlUnawareOfSingleton,
  type GqlSingletonResult,
  graphql,
  type GraphqlClient,
} from '@directus/sdk';
import { createTranslationsParams } from '@casumo/fabric-fundamentals';
import { FALLBACK_EN_LOCALE, PUBLIC_DIRECTUS_URL } from 'Constants';
import {
  addTranslationsFilterAndSort,
  appendFilterAndSortParameters,
  createTranslationsFiltersObject,
  mappedLocaleCode,
} from 'Utils';
import type * as DirectusTypes from 'Src/directus.types';
import { type TMarketingWidgetTranslations } from '../components/MarketingWidget/MarketingWidget.types';
import { type TAdventureProgressTranslations } from '../components/AdventureProgressBar/AdventureProgressBar.types';
import { type TValuableTermsAndConditionsTranslations } from 'Components/ValuableDetailsPopUp/ValuableDetailsPopUp.types';
import { type TQuickEmailMarketingWidgetTranslations } from 'Components/QuickEmailMarketingWidget/QuickEmailMarketingWidget.types';

export type TDirectusCollections = {
  market_config: DirectusTypes.MarketConfig;
  market_config_by_id: {
    toasts: Array<{
      toasts_id: DirectusTypes.Toasts[];
    }>;
  };
  hero_module: DirectusTypes.HeroModule;
  terms_and_conditions: DirectusTypes.TermsAndConditions;
  marketing_widget_translations: TMarketingWidgetTranslations;
  email_marketing_widget_translations: TQuickEmailMarketingWidgetTranslations;
  adventure_progress_translations: TAdventureProgressTranslations;
  valuables: DirectusTypes.Valuables;
  valuables_terms_and_conditions: TValuableTermsAndConditionsTranslations;
  popups: DirectusTypes.Popups;
  promotion_lists: DirectusTypes.PromotionLists;
  reel_race_promo_page: DirectusTypes.ReelRacePromoPage;
  adventure_promo_page: DirectusTypes.AdventurePromoPage;
  reel_races: DirectusTypes.ReelRaces;
  retention_content: DirectusTypes.RetentionContent;
  achievements: DirectusTypes.Achievements;
  safe_promotions_page: DirectusTypes.SafePromotionsPage;
  curated_card_list: DirectusTypes.CuratedCardList;
  reel_races_hero: DirectusTypes.ReelRacesHero;
  ridabodo_widget: DirectusTypes.RidabodoWidget;
  promotions: DirectusTypes.Promotions;
  fame: DirectusTypes.Fame;
  reel_race_leader_board: DirectusTypes.ReelRaceLeaderBoard;
  reel_race_prize_pool: DirectusTypes.ReelRacePrizePool;
  deposit_valuable_picker: DirectusTypes.DepositValuablePicker;
  ridabodo_info_drawer: DirectusTypes.RidabodoInfoDrawer;
  games: DirectusTypes.Games;
  ingame_reel_race_widget: DirectusTypes.IngameReelRaceWidget;
  reel_race_restart: DirectusTypes.ReelRaceRestart;

  // TODO: replace with the right type from @casumo/directus-api once it's ready
  terms_and_conditions_translations: Array<{ title: string; content: string }>;
};

type GqlResult<Schema extends object, Collection extends keyof Schema> = {
  [Key in Collection]: Schema[Collection] extends { singleton: true }
    ? GqlSingletonResult<Schema, Collection>[Key]
    : GqlUnawareOfSingleton<Schema, Collection>[Key];
};

export type TGqlResult<T extends keyof TDirectusCollections> = GqlResult<TDirectusCollections, T>;

type TDirectusContextType = GraphqlClient<TDirectusCollections> | null;

const DirectusContext = createContext<TDirectusContextType>(null);

type TProps = {
  children: React.ReactNode;
};

type TLocalizedQueryProps = {
  query: string;
  translationsNodeNames: string[];
  variables?: Record<string, any>;
};

export type TLocalizedQuery<T> = ({ query, variables }: TLocalizedQueryProps) => Promise<T>;

export const DirectusProvider: React.FC<TProps> = ({ children }) => {
  const directus = createDirectus<TDirectusCollections>(PUBLIC_DIRECTUS_URL).with(graphql());

  return <DirectusContext.Provider value={directus}>{children}</DirectusContext.Provider>;
};

export const useDirectus = (): GraphqlClient<TDirectusCollections> => {
  const directus = useContext(DirectusContext);

  if (!directus) {
    throw new Error('useDirectus must be used within a DirectusProvider');
  }

  return directus;
};

export const useDirectusLocalizedQuery = <T extends keyof TDirectusCollections>(): TLocalizedQuery<
  TGqlResult<T>
> => {
  const directusGqlClient = useDirectus();
  const localeCode = mappedLocaleCode();

  const { filter, sort: translationsSort } = createTranslationsParams(
    localeCode,
    FALLBACK_EN_LOCALE
  );

  const localizedQuery = React.useCallback(
    async ({ query, translationsNodeNames, variables }: TLocalizedQueryProps) => {
      const requireSort = translationsSort !== undefined;
      const queryWithFilterParams = appendFilterAndSortParameters(
        query,
        translationsNodeNames,
        requireSort
      );
      const queryWithTranslationsFilter = addTranslationsFilterAndSort(
        queryWithFilterParams,
        translationsNodeNames,
        requireSort
      );
      // todo: maybe we want to consolidate the sln on fundamentals for gql queries
      const translationsFilter = {
        languages_code: { code: filter.languages_code },
      };
      const translationsFilters = createTranslationsFiltersObject(
        translationsNodeNames,
        translationsFilter
      );
      const result = await directusGqlClient.query<TGqlResult<T>>(queryWithTranslationsFilter, {
        ...translationsFilters,
        translationsSort,
        ...variables,
      });

      return result;
    },
    [directusGqlClient, localeCode]
  );

  return localizedQuery;
};
