import { type Root } from 'react-dom/client';
import '@casumo/fabric-fundamentals';
import * as components from '@casumo/fabric-components';
import { createWebComponent, createApplicationContainer, createStylesLink } from './packager/index';
import { renderApplication } from './app';

import { APPLICATION_NAME, NAMESPACE } from './fabric.constants';

import './index.css';
import './index.scss';
import { type TWebComponentAttributes } from 'Components/types';

components.define(NAMESPACE);

createWebComponent<Root>(
  APPLICATION_NAME,
  (root, attributes, host, onReady) => {
    Promise.all([
      createApplicationContainer(root),
      createStylesLink(root, `${host}/index.css`),
    ]).then(([container]) => {
      renderApplication(root, container, attributes as TWebComponentAttributes, onReady);
    });
  },
  (node) => {
    console.debug('cleanup after ourselves 🧹', node);
  },
  true
);
