import { useCallback } from 'react';
import { useToggle } from 'usehooks-ts';

export const usePopUp = (): [boolean, () => void, () => void] => {
  const [isOpen, , setIsOpen] = useToggle(false);
  const openPopUp = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);
  const closePopUp = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  return [isOpen, openPopUp, closePopUp];
};
