import { useLocalStorage } from 'usehooks-ts';
import { useLoggedInState } from './useLoggedInState';
import { useEffect } from 'react';

const HAS_PREVIOUSLY_LOGGED_IN = 'hasPreviouslyLoggedIn';

export const useHasAccountTracker = () => {
  const loggedInState = useLoggedInState({ isLoggedIn: true });

  const [hasPreviouslyLoggedIn, setHasPreviouslyLoggedIn] = useLocalStorage(
    HAS_PREVIOUSLY_LOGGED_IN,
    false
  );

  useEffect(() => {
    if (loggedInState?.isLoggedIn && !hasPreviouslyLoggedIn) {
      setHasPreviouslyLoggedIn(true);
    }
  }, [loggedInState?.isLoggedIn]);

  return hasPreviouslyLoggedIn;
};
