import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export type TMarkNotificationAsReadArgs = {
  playerId: string;
  notificationId: string;
};

export type TMarkAllNotificationsAsReadArgs = {
  playerId: string;
};

export const CommonNotificationsApi = createApi({
  reducerPath: 'CommonNotificationsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/',
    prepareHeaders: (headers) => {
      headers.set('Content-Type', 'application/json');
      return headers;
    },
  }),
  endpoints: (builder) => ({
    commonMarkAsRead: builder.mutation<any, TMarkNotificationAsReadArgs>({
      query: ({ playerId, notificationId }) => ({
        headers: {
          'X-Player-Id': playerId,
        },
        url: 'api/common/command/markNotificationAsRead',
        method: 'POST',
        body: { notificationId },
      }),
    }),
    commonMarkAllAsRead: builder.mutation<any, TMarkAllNotificationsAsReadArgs>({
      query: ({ playerId }) => ({
        headers: {
          'X-Player-Id': playerId,
        },
        url: 'api/common/command/markAllNotificationAsRead',
        method: 'POST',
      }),
    }),
  }),
});

export const { useCommonMarkAsReadMutation, useCommonMarkAllAsReadMutation } =
  CommonNotificationsApi;
