import { useCallback, useState } from 'react';
import { useDirectusLocalizedQuery } from './useDirectus';
import type * as DirectusTypes from 'Src/directus.types';
import { snakeToCamel } from 'Utils';

export const MATCH_FILTERS = {
  ISTARTS_WITH: '_istarts_with',
  EQUAL: '_eq',
  CONTAINS: '_icontains',
} as const;

export type TQueryMatchFilters = (typeof MATCH_FILTERS)[keyof typeof MATCH_FILTERS];

export type TRetentionContentFilter = {
  name: {
    [key in TQueryMatchFilters]?: string;
  };
};

const retentionContentFilter = (
  matchFilter: TQueryMatchFilters,
  match: string
): TRetentionContentFilter => ({
  name: {
    [matchFilter]: match,
  },
});

export const startsWithFilter = (match: string) =>
  retentionContentFilter(MATCH_FILTERS.ISTARTS_WITH, match);

export const equalFilter = (match: string) => retentionContentFilter(MATCH_FILTERS.EQUAL, match);

export const containsFilter = (match: string) =>
  retentionContentFilter(MATCH_FILTERS.CONTAINS, match);

export const parseRetentionContent = (
  data: DirectusTypes.RetentionContent[]
): Record<string, string> => {
  const retentionContent = data ?? [];

  return retentionContent.reduce<Record<string, string>>((records, { name, translations }) => {
    const translation = translations[0]?.content;
    if (name && translation) {
      return { ...records, [name]: translation };
    }
    return records;
  }, {});
};

export const camelCaseRetentionContentKeys = (items: Record<string, string>) => {
  return Object.keys(items)?.reduce<Record<string, string>>((acc, key) => {
    const camelKey = snakeToCamel(key?.split('.')?.pop() ?? '');
    return { ...acc, [camelKey]: items[key] };
  }, {});
};

const RETENTION_CONTENT_QUERY = `
  query Retention_content ($filter: retention_content_filter) {
    retention_content (filter: $filter) {
      name
      translations {
        content
        asset {
          id
        }
      }
    }
  }
`;

export const useFetchRetentionContent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const localizedQuery = useDirectusLocalizedQuery<'retention_content'>();

  const fetch = useCallback(
    async (filter: TRetentionContentFilter): Promise<DirectusTypes.RetentionContent[]> => {
      setIsLoading(true);

      const data = await localizedQuery({
        query: RETENTION_CONTENT_QUERY,
        translationsNodeNames: ['retention_content'],
        variables: { filter },
      });

      setIsLoading(false);

      return data?.retention_content || [];
    },
    [setIsLoading, localizedQuery]
  );

  return { fetch, isLoading };
};
