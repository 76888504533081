import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type TRootState } from 'Store';

const initialState: string[] = [];

const PopUpsSlice = createSlice({
  name: 'popUps',
  initialState,
  reducers: {
    addPopUpVisible(state, data: PayloadAction<string>) {
      if (state.includes(data.payload)) return state;
      return [...state, data.payload];
    },
    removePopUpVisible(state, data: PayloadAction<string>) {
      if (!state.includes(data.payload)) return state;
      return state.filter((popUpId) => popUpId !== data.payload);
    },
    clearPopUpsVisible() {
      return [];
    },
  },
});

export default PopUpsSlice;
export const { addPopUpVisible, removePopUpVisible, clearPopUpsVisible } = PopUpsSlice.actions;
export const selectVisiblePopUps = (state: TRootState) => state.popUps;
