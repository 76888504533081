import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type ToastPayloads } from '@casumo/fabric-fundamentals';
import { type ToastsTranslations, type Toasts } from 'Src/directus.types';
import { type TToastKind } from '../components/Notifications/Toast/Toast.types';

type TToastOverrideBase = { id: string; thumbnail_image?: string; kind: TToastKind };
type TToastOptionalFields = {
  optionalPayload?: {
    valuableId?: string;
    stateBadgeId?: string;
    origin?: 'persistent-notifications' | 'common-notifications';
  };
};

// todo: pull these types from @casumo/directus-api when
type TToastTranslations = Pick<
  ToastsTranslations,
  'title' | 'message' | 'primary_button_text' | 'secondary_button_text'
>;

type TToasts = Pick<Toasts, 'name' | 'autoclose_duration' | 'priority'>;

export type TToast = TToastOverrideBase & TToasts & TToastOptionalFields & TToastTranslations;

export type TToastPayload = ToastPayloads;

type TToastSlice = {
  toasts: TToast[];
};

const initialState: TToastSlice = {
  toasts: [],
};

export const ToastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    addToast: (state, action: PayloadAction<TToast>) => {
      state.toasts.push(action.payload);
    },
    removeToast: (state, action: PayloadAction<string>) => {
      state.toasts = state.toasts.filter((toast) => toast.id !== action.payload);
    },
  },
});

export const { addToast, removeToast } = ToastSlice.actions;
