import { PlayerApi } from '../player/playerApi';
import { type TGamificationFeaturesResponse } from './gamificationFeatures.types';

const API_URL_GET_GAMIFICATION_FEATURES =
  '/player-gamification-feature-toggle/api/v1/player/features';

export const gamificationFeaturesApi = PlayerApi.injectEndpoints({
  endpoints: (builder) => ({
    getGamificationFeatures: builder.query<TGamificationFeaturesResponse, void>({
      query: () => ({
        method: 'GET',
        url: API_URL_GET_GAMIFICATION_FEATURES,
      }),
      keepUnusedDataFor: 1,
    }),
  }),
});

export const { useGetGamificationFeaturesQuery } = gamificationFeaturesApi;
