import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Pusher from 'pusher-js';
import { selectPlayerId } from 'Store';
import { PUSHER_CONSTANTS, THUNDERBYTE_SESSION_MAPPING } from 'Constants';
import { isDevEnv, isTestEnv, noop, isInboxMessage, getEnv } from 'Utils';
import { useLoggedInState } from 'Hooks';
import { gatekeeper } from 'Src/gatekeeper';

export type TFTEventTypes = 'shoutout' | 'message' | 'inbox';

export type TFTMsgCallback = (data: TFTMessage) => unknown;

export type TInboxTranslations = {
  recieved: string;
  validUntil: string;
  questionsTop: string;
  questionsShort: string;
  deleteMessagePrompt: string;
  inboxEmpty: string;
  readMore: string;
  backToList: string;
  inboxHeader: string;
  pendingBonusesEmpty: string;
  pendingBonusesTitle: string;
  pendingBonusesSubTitle: string;
  pendingBonusesExpiresIn: string;
  pendingBonusesExpires: string;
  pendingBonusesCTAUnlockText: string;
  pendingBonusesCTAClaimFallbackText: string;
  pendingBonusesBackButtonText: string;
  pendingBonusesTermsAndConditionsHeader: string;
  pendingBonusesBonusUnlockedText: string;
  days: string;
  hours: string;
  minutes: string;
};

export type TFTMessage = {
  CTAButton2Link: string;
  CTAButton2Text: string;
  CTAButtonLink: string;
  CTAButtonText: string;
  Data: {
    OverrideCommunicationStatus: string;
    loginMessage?: string;
    curatedCardContent?: string;
    liveCasinoCuratedCardContent?: string;
    'hero-slug': string;
    thunderbite?: string;
    casinoPromotionsContent?: string;
  };
  Date: string;
  DisplayType: string;
  Event: TFTEventTypes;
  Expires: string;
  FooterText: string;
  ImageUrl: string;
  IsRead: string;
  Message: string;
  MessageId: string;
  PreviewText: string;
  Title: string;
};

const initiatePusher = (
  fusionUrl: string,
  pusherKey: string,
  channelName: string,
  region: string,
  sid: string,
  onInitCompleted: (p: Pusher) => void = noop,
  onNewMsg: (m: TFTMessage) => void
) => {
  const BRAND_NAME = 'casumocrm';

  const PUSHER = new Pusher(pusherKey, {
    authEndpoint: `${fusionUrl}/external/pusher/${BRAND_NAME}?authToken=${sid}`,
    cluster: region,
    forceTLS: true,
  });

  const channel = PUSHER.subscribe(channelName);

  channel.bind('inbox', onNewMsg);
  channel.bind('shoutout', onNewMsg);
  channel.bind('message', onNewMsg);
  channel.bind('crm_campaign', onNewMsg);

  PUSHER.connection.bind('connected', onInitCompleted);
  return channel;
};

export function useFTMessaging() {
  const [messages, setMessages] = useState<TFTMessage[]>([]);
  const [fusionUrl, setFusionUrl] = useState<string>('');
  const [ftToken, setFtToken] = useState<string>('');
  const [pusher, setPusher] = useState<Pusher | null>(null);

  const playerId = useSelector(selectPlayerId);

  const gatekeeperIsLegacy = gatekeeper.mode === 'legacy';
  const loggedInState = useLoggedInState({
    sessionId: gatekeeperIsLegacy,
    accessToken: !gatekeeperIsLegacy,
  });

  const filterOutMsg = useCallback(
    (id: string) => {
      setMessages(messages.filter((m) => m.MessageId !== id));
    },
    [messages]
  );

  const getUnread = useMemo(
    () => getUnreadMessageHandler(fusionUrl, ftToken),
    [Boolean(fusionUrl), Boolean(ftToken)]
  );

  const markAsRead = useMemo(
    () => getMarkAsReadHandler(fusionUrl, ftToken, filterOutMsg),
    [Boolean(fusionUrl), Boolean(ftToken), filterOutMsg]
  );

  const deleteMessage = useMemo(
    () => getDeleteHandler(fusionUrl, ftToken, filterOutMsg),
    [Boolean(fusionUrl), Boolean(ftToken), filterOutMsg]
  );

  const pusherReadyCallback = useCallback(
    (p: Pusher) => {
      if (loggedInState?.accessToken) {
        pingPageLoadedWithToken(loggedInState.accessToken);
      } else if (loggedInState?.sessionId && playerId) {
        pingPageLoadedWithSession(loggedInState.sessionId, playerId);
      }
      setPusher(p);
    },
    [loggedInState?.accessToken, loggedInState?.sessionId, playerId]
  );

  const onNewMsg = useCallback((m: TFTMessage) => {
    setMessages((p) => [m, ...p]);
  }, []);

  const init = useCallback(async () => {
    const accessToken = loggedInState?.accessToken;
    const sessionId = loggedInState?.sessionId;
    if (pusher ?? (!accessToken && !sessionId)) {
      return;
    }

    try {
      const { pusherKey, fusionUrl: fUrl, pusherRegion } = await getBaseEndpoints();
      let externalSessionId = '';

      if (accessToken) {
        externalSessionId = await getExternalSessionIDFromToken(accessToken);
      } else {
        externalSessionId = await getExternalSessionIDFromSession(sessionId);
      }
      if (!externalSessionId) return;

      const { Data } = await loginFT(fUrl, externalSessionId);

      const channel = 'private-prisma-223-' + String(Data?.User?.UserId);

      setFusionUrl(fUrl);
      setFtToken(Data?.Authentication?.AuthToken);

      initiatePusher(
        fUrl,
        pusherKey,
        channel,
        pusherRegion,
        Data?.Authentication?.AuthToken,
        pusherReadyCallback,
        onNewMsg
      );
    } catch (error) {
      console.error(error);
    }
  }, [loggedInState?.accessToken, loggedInState?.sessionId, pusher, onNewMsg, pusherReadyCallback]);

  useEffect(
    function initializeWS() {
      if (!playerId || pusher) {
        return;
      }

      const timeoutID = setTimeout(init as () => void, 1500);

      return () => {
        clearTimeout(timeoutID);
        // @ts-expect-error //remove this to see full error, fix it if you know what is the issue.
        pusher?.disconnect();
      };
    },
    [playerId, init, pusher]
  );

  useEffect(
    function loadUnreadInboxMessages() {
      if (!fusionUrl || !ftToken) {
        return;
      }

      if (isDevEnv() || isTestEnv()) {
        getUnread()
          .then(({ Data: msgs }) => msgs.filter(isInboxMessage))
          .then((msgs) => {
            setMessages(msgs);
          });
      }
    },
    [fusionUrl, ftToken, getUnread]
  );

  return {
    messages,
    init,
    getUnread,
    markAsRead,
    deleteMessage,
  };
}

function getMarkAsReadHandler(
  fusionUrl: string,
  authToken: string,
  onSuccess: (id: string) => void
) {
  if (!fusionUrl || !authToken) {
    return async () => await Promise.reject(new Error('No auth token ready yet'));
  }

  return async (id: string) => {
    await fetch(`${fusionUrl}/Notifications/MarkNotificationAsRead`, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'content-type': 'application/json',
        authtoken: authToken,
      },
      body: JSON.stringify({
        MessageId: id,
      }),
    }).then(() => {
      onSuccess(id);
    });
  };
}

function getDeleteHandler(fusionUrl: string, authToken: string, onSuccess: (id: string) => void) {
  if (!fusionUrl || !authToken) {
    return async () => await Promise.reject(new Error('No auth token ready yet'));
  }

  return async (id: string) => {
    await fetch(`${fusionUrl}/Notifications/v2/user-notification/${id}`, {
      method: 'DELETE',
      credentials: 'same-origin',
      headers: {
        'content-type': 'application/json',
        authtoken: authToken,
      },
    }).then(() => {
      onSuccess(id);
    });
  };
}

function getUnreadMessageHandler(fusionUrl: string, authToken: string) {
  if (!fusionUrl || !authToken) {
    return async () => await Promise.reject(new Error('No auth token ready yet'));
  }

  return async () =>
    await (fetch(
      `${fusionUrl}/Notifications/v2/user-notifications?unread-only=true&inbox-only=true`,
      {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
          'content-type': 'application/json',
          authtoken: authToken,
        },
      }
    ).then(async (response) => await response.json()) as Promise<{ Data: TFTMessage[] }>);
}

function getFTConfigUrl() {
  if (
    process.env.NODE_ENV === 'production' &&
    !window?.location?.href?.includes('casumotest.com')
  ) {
    return PUSHER_CONSTANTS.CONFIG_URL_PRODUCTION;
  } else {
    return PUSHER_CONSTANTS.CONFIG_URL_STAGING;
  }
}

async function getBaseEndpoints() {
  return await fetch(getFTConfigUrl()).then(async (response) => await response.json());
}

type TFastTrackCasumoIntegration = {
  externalSessionId: string;
  playerId: string;
};

export async function getThunderByteSessionToken(
  sessionId: string
): Promise<TFastTrackCasumoIntegration> {
  return await fetch(THUNDERBYTE_SESSION_MAPPING, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      'content-type': 'application/json',
      'X-Token': sessionId,
    },
  }).then(async (response) => await response.json());
}

export async function getExternalSessionIDFromToken(authToken = ''): Promise<string> {
  const externalSessionURL = `'https://api.${getEnv()}/player-api${
    PUSHER_CONSTANTS.EXTERNAL_SESSION_URL
  }`;
  return await fetch(externalSessionURL, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  })
    .then(async (response) => await response.json())
    .then((ftResponse) => ftResponse.externalSessionId);
}

export async function getExternalSessionIDFromSession(sessionId = ''): Promise<string> {
  const externalSessionURL = `/casino-player${PUSHER_CONSTANTS.EXTERNAL_SESSION_URL}`;
  return await fetch(externalSessionURL, {
    headers: {
      'X-Token': sessionId,
    },
  })
    .then(async (response) => await response.json())
    .then((ftResponse) => ftResponse.externalSessionId);
}

async function loginFT(fusionUrl: string, externalSessionId: string): Promise<any> {
  return await fetch(`${fusionUrl}/Platform/LoginAuthToken`, {
    method: 'POST',
    headers: {
      authtoken: externalSessionId,
    },
  }).then(async (response) => await response.json());
}

export const pingPageLoadedWithToken = async (authToken: string): Promise<any> => {
  const pageLoadControllerEndPoint = `https://api.${getEnv()}/player-api${
    PUSHER_CONSTANTS.PAGE_LOADED
  }`;
  return await fetch(pageLoadControllerEndPoint, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });
};

export const pingPageLoadedWithSession = async (
  sessionId: string,
  playerId: string
): Promise<any> => {
  const pageLoadControllerEndPoint = `/casino-player/${PUSHER_CONSTANTS.PAGE_LOADED}`;
  return await fetch(pageLoadControllerEndPoint, {
    method: 'POST',
    headers: {
      'X-Token': sessionId,
    },
    body: JSON.stringify({
      playerId,
    }),
  });
};
