import { useEffect } from 'react';
import type { FabricTopicToPayload, MetaData } from '@casumo/fabric-fundamentals';
import { gatekeeper } from 'Src/gatekeeper';
import { APPLICATION_NAME } from 'Src/fabric.constants';

type TFabricEventTopics = keyof FabricTopicToPayload;

type TPseudoFabricTopic = 'test';

type TPseudoFabricPayload = {
  marketSlug: string;
  localeCode: string;
  frontend: string;
};

const getPseudoPayload = () => ({
  frontend: APPLICATION_NAME,
  ...gatekeeper.localisation,
});

// @chrisc: treat this hook as just an example for now
const useMessageBus = () => {
  const publishPseudoEvent = () => {
    // @ts-expect-error-error: this method is not typed as it is example only
    gatekeeper.messageBus.publish('test', getPseudoPayload());
  };

  const subscribe = (
    eventName: TFabricEventTopics | TPseudoFabricTopic,
    handler: (
      payload: FabricTopicToPayload[TFabricEventTopics] | TPseudoFabricPayload,
      metaData: MetaData
    ) => void
  ) => {
    useEffect(() => {
      gatekeeper.messageBus.subscribe(eventName as TFabricEventTopics, handler);

      return () => {
        // do we need to unsubscribe from the message bus. eg:
        // gatekeeper.messageBus.unsubscribe(eventName, handler);
      };
    }, [eventName, handler]);
  };

  return {
    publishPseudoEvent,
    subscribe,
  };
};

export default useMessageBus;
