import { type Languages } from 'Src/directus.types';
import { DIRECTUS_ASSET_URL, PUBLIC_CMS_IMGIX_URL } from 'Constants';
import { isEmpty, pathOrDefault, snakeToCamel } from 'Utils';

type TBaseTranslation = {
  id: number;
  languages_code?: string | Languages | null;
};

type TTranslated<T extends TBaseTranslation> = {
  translations?: T[] | null;
};

type TTranslationsFiltersObject = Record<
  string,
  {
    languages_code: {
      code: string;
    };
  }
>;

export function translate<T extends TBaseTranslation>(
  translated: TTranslated<T>,
  languages_code?: string,
  hasFallback?: boolean
): T {
  if (hasFallback) {
    return (translated.translations?.[0] as T) ?? (translated.translations?.[1] as T);
  }

  // todo: deprecate the below code once all translate usages are expecting result from localizedQuery
  const translation = languages_code
    ? translated.translations?.find((t) => {
        return typeof t?.languages_code === 'string'
          ? t?.languages_code === languages_code
          : t?.languages_code?.code === languages_code;
      })
    : translated.translations?.[0];

  if (!translation) {
    throw new Error('Missing translation');
  }

  return translation;
}

export const getTranslationsWithFallback = <T extends Record<string, string | number | null>>(
  translations: T,
  fallback: string | undefined = undefined
): T => {
  const result: any = {};

  try {
    for (const [key, value] of Object.entries(translations)) {
      result[key] = pathOrDefault(value ?? fallback, [`${key}`], translations);
    }
  } catch (err) {
    console.error('No Translations Provied', err);
  }
  return result as T;
};

export const addTranslationsFilterAndSort = (
  originalQuery: string,
  translationsNodeNames: string[],
  requireSort: boolean
): string => {
  const translationsDefinitionRegex = /(translations\s*{)/g;
  const getTranslationsFilterString = (translationsNodeName: string) =>
    `filter: $${snakeToCamel(translationsNodeName)}TranslationsFilter`;
  const translationsFilterParam = translationsNodeNames.map(getTranslationsFilterString);
  const sortFilterParam = requireSort ? `, sort: $translationsSort` : '';
  let currentIdx = 0;

  try {
    return originalQuery.replace(translationsDefinitionRegex, () => {
      const replacement = `translations (${translationsFilterParam[currentIdx]}${sortFilterParam}) {`;
      currentIdx++;

      if (currentIdx <= translationsNodeNames.length) {
        return replacement;
      } else {
        return 'translations {'; // In case there are more matches than elements in translationsNodeNames
      }
    });
  } catch (error) {
    console.warn('Failed to add translations filter and sort to query:', error);
    return originalQuery;
  }
};

// todo: need to support cases where we have multiple 'translations' nodes in the query
export const appendFilterAndSortParameters = (
  originalQuery: string,
  translationsNodeNames: string[],
  requireSort: boolean
): string => {
  const queryDefinitionRegex = /query\s+(\w+)\s*(\([^)]*\))?\s*{/;
  const match = originalQuery.match(queryDefinitionRegex);
  const getTranslationsFilterString = (translationsNodeName: string) =>
    `$${snakeToCamel(
      translationsNodeName
    )}TranslationsFilter: ${translationsNodeName}_translations_filter!`;

  const translationsFilterParam = translationsNodeNames.map(getTranslationsFilterString).join(', ');
  const sortFilterParam = requireSort ? `, $translationsSort: [String])` : ')';

  if (match) {
    const [, queryName, parameters] = match;
    const updatedParameters = parameters ? `${parameters.replace(')', '')}, ` : '(';
    const updatedQuery = originalQuery.replace(
      queryDefinitionRegex,
      `query ${queryName}${updatedParameters}${translationsFilterParam}${sortFilterParam} {`
    );
    return updatedQuery;
  }

  console.warn('Failed to update query definition');
  return originalQuery;
};

export const createTranslationsFiltersObject = (
  translationsNodeNames: string[],
  filter: any
): TTranslationsFiltersObject => {
  return translationsNodeNames.reduce((acc: TTranslationsFiltersObject, nodeName) => {
    const camelCaseName = snakeToCamel(nodeName) + 'TranslationsFilter';
    acc[camelCaseName] = filter;
    return acc;
  }, {});
};

export function getAssetURL(id: string) {
  if (!isEmpty(id)) {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    return `${DIRECTUS_ASSET_URL}/${id}`;
  }

  return undefined;
}

export function getImgixURL(fileName: string) {
  if (!isEmpty(fileName)) {
    return `${PUBLIC_CMS_IMGIX_URL}/${fileName}`;
  }

  return undefined;
}

export const getGameThumbnailImgixParams = (mark?: string) => {
  const params = {
    w: 250,
    fit: 'crop',
    markscale: 95,
    auto: 'compress',
    fm: 'jpg',
    markalign: 'top,center',
    markfit: 'max',
    crop: 'top,faces',
    dpr: 1,
    mark: mark ?? '',
  };

  const textParams = Object.entries(params).map(([key, value]) => [key, value?.toString()]);
  const urlParams = new URLSearchParams(textParams).toString();
  return urlParams;
};

export const convertWordpressUrlToImgix = (url: string) => {
  if (!url) {
    return '';
  }

  url = url.replace('cms.casumo.com', 'images-cms.casumo.com');
  url = url.replace('data-cms-casumo-com.storage.googleapis.com', 'images-cms.casumo.com');
  url = url.replace('wp-content/uploads/', '');

  return url;
};

export const getImgixUrl = (imageUrl: string, markUrl?: string) => {
  const image = convertWordpressUrlToImgix(imageUrl);
  const mark = markUrl ? convertWordpressUrlToImgix(markUrl) : undefined;
  const urlParams = getGameThumbnailImgixParams(mark);
  return `${image}?${urlParams}`;
};
