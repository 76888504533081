import React, { createContext, useContext } from 'react';
import { useDirectus, type TGqlResult } from './useDirectus';
import type * as DirectusTypes from 'Src/directus.types';

export type TCmsMarketConfigContextType = Pick<DirectusTypes.MarketConfig, 'id' | 'slug'> | null;

const CmsMarketConfigContext = createContext<TCmsMarketConfigContextType>(null);

type TProps = {
  slug: string;
  children: React.ReactNode;
};

export const CmsMarketConfigProvider: React.FC<TProps> = ({ children, slug }) => {
  const directus = useDirectus();
  const [isLoading, setIsLoading] = React.useState(true);
  const [value, setValue] = React.useState<TCmsMarketConfigContextType>(null);

  React.useEffect(() => {
    (async function () {
      try {
        const data = await directus.query<TGqlResult<'market_config'>>(`
      {
        market_config(filter: {slug: {_eq: "${slug}"}}) {
          id
          slug
        }
      }`);

        if (data?.market_config[0]?.id) {
          setValue({ ...data?.market_config[0] });
          setIsLoading(false);
        }
      } catch {
        setIsLoading(false);
        throw new Error('Error on market config query');
      }
    })();
  }, []);

  if (isLoading || !value) {
    return null;
  }

  return (
    <CmsMarketConfigContext.Provider value={value}>{children}</CmsMarketConfigContext.Provider>
  );
};

export const useCmsMarketConfig = (): TCmsMarketConfigContextType => {
  const cmsMarketConfig = useContext(CmsMarketConfigContext);

  if (!cmsMarketConfig) {
    throw new Error('useCmsMarketConfig must be used within a CmsMarketConfigProvider');
  }

  return cmsMarketConfig;
};
