import { jurisdictionCheck } from 'Utils';
import { useGetGamificationFeaturesQuery } from 'Services/gamificationFeatures';
import { useGetEsMarketingExclusionStatus } from './useGetEsMarketingExclusionStatus';

export function useSafePromotions() {
  const { isUKGC, isDGOJ } = jurisdictionCheck();

  const { data: gamificationFeatures, isLoading: isFeaturesLoading } =
    useGetGamificationFeaturesQuery();

  const { marketingExclusionStatus: isEsExcludedFromMarketing, isLoading: isMarketingLoading } =
    useGetEsMarketingExclusionStatus();

  const rgRiskLevelBlock = gamificationFeatures?.rgRiskLevelBlock as boolean;

  const isPlayerPromotionsRestricted =
    ((isUKGC || isDGOJ) && rgRiskLevelBlock) || (isDGOJ && isEsExcludedFromMarketing);

  return {
    isPlayerPromotionsRestricted,
    isSafePromotionLoading: isFeaturesLoading || isMarketingLoading,
  };
}
