import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { getEnv } from 'Utils';
import { gatekeeper } from 'Src/gatekeeper';

const isLegacyMode = gatekeeper.mode === 'legacy';
export const apiBaseUrl = isLegacyMode ? '/player' : `https://api.${getEnv()}`;

const casumoFetch = gatekeeper.fetch;

const GUARDED_API_URL = `${apiBaseUrl}/player-api`;

export const BaseQueryBuilder = (baseUrl: string) => {
  return fetchBaseQuery({
    baseUrl,
    fetchFn: async (input, init) => {
      const url = (input as any).url as string;

      if (url.startsWith(GUARDED_API_URL)) {
        return await casumoFetch(input, init);
      }

      return await window.fetch(input, init);
    },
  });
};
