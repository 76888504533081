import { PlayerApi } from '../player/playerApi';
import { VALUABLE_STATE } from 'Services/valuables';
import { getPlatform } from 'Utils';
import {
  type TRewards,
  type TPlayerRewardResponse,
  type TReward,
  type TPlayerValuablePayload,
} from './rewardApi.types';

const platform = getPlatform();
const API_BASE_URL = '/rewards/api/v1/players/rewards';

const PlayerRewardsApi = PlayerApi.enhanceEndpoints({ addTagTypes: ['Valuables'] });

export const RewardsApi = PlayerRewardsApi.injectEndpoints({
  endpoints: (builder) => ({
    getPlayerRewards: builder.query<TRewards, TPlayerValuablePayload & { playerId?: string }>({
      query: ({
        playerId,
        showAllStates = false,
        matchingStates = [VALUABLE_STATE.FRESH, VALUABLE_STATE.USED],
        excludingStates = [],
        showAllRewardTypes = false,
        matchingRewardTypes = [],
        pageNumber = 1,
        pageSize = 20,
      }) => {
        return {
          headers: {
            'Content-Type': 'application/json',
            'X-Player-Id': playerId,
          },
          url: `${API_BASE_URL}?showAllStates=${String(showAllStates)}${String(
            matchingStates?.reduce(
              (query, matchingState) => `${query}&matchingStates=${matchingState || ''}`,
              ''
            )
          )}${excludingStates?.reduce(
            (query, excludingState) => `${query}&excludingStates=${excludingState || ''}`,
            ''
          )}${
            showAllRewardTypes ? `&showAllRewardTypes=${String(showAllRewardTypes)}` : ''
          }${matchingRewardTypes?.reduce(
            (query, matchingRewardType) =>
              `${query}&matchingRewardTypes=${matchingRewardType || ''}`,
            ''
          )}&pageNumber=${String(pageNumber)}&pageSize=${String(pageSize)}
          `,
        };
      },
      transformResponse: (response: TPlayerRewardResponse) => {
        return response?.rewards ?? [];
      },
      providesTags: ['Valuables'],
    }),
    getReward: builder.query<TReward, { badgeId: string }>({
      query: ({ badgeId }) => ({
        url: `${API_BASE_URL}/${badgeId}`,
      }),
    }),
    makeUseOfReward: builder.mutation<void, { badgeId: string; state?: string; option?: number }>({
      query: ({ badgeId, state = VALUABLE_STATE.USED, option }) => ({
        method: 'PATCH',
        url: `${API_BASE_URL}/${badgeId}`,
        body: {
          state,
          option,
          platform,
        },
      }),
      invalidatesTags: ['Valuables'],
    }),
  }),
});

export const {
  useGetPlayerRewardsQuery,
  useLazyGetPlayerRewardsQuery,
  useLazyGetRewardQuery,
  useMakeUseOfRewardMutation,
} = RewardsApi;
