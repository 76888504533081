export const VALUABLE_TYPE = {
  REAL_MONEY: 'realMoney',
  BONUS_MONEY: 'bonusMoney',
  SIMPLE_FREE_SPINS: 'simpleFreeSpins',
  SIMPLE_BONUS_SPINS: 'simpleBonusSpins',
  DEPOSIT_BONUS: 'depositBonus',
  CASHBACK: 'cashback',
  SPORTS_DEPOSIT_BONUS: 'sportsDepositBonus',
  KAMBI_FREE_BET: 'kambiFreeBet',
  KAMBI_PROFIT_BOOST: 'kambiProfitBoost',
  LIVE_CASINO_FREE_BET: 'liveCasinoFreeBet',
  RACE_INVITATION: 'raceInvitation',
  COMPOSITE_REWARD: 'multipleOptions',

  // todo: check if we can return actual type from backend and remove these
  // these are the returned types for composite reward inner items
  // pseudo valuable types for uncreated valuables
  SPINS: 'spins',
  MONEY: 'money',
  SPORTS_FREE_BET: 'sportsFreeBet',
  KAMBI_OFFER: 'kambiOffer',
} as const;

export const VALUABLE_STATE = {
  FRESH: 'Fresh',
  USED: 'Used',
  LOCKED: 'Locked',
  CONSUMED: 'Consumed',
  EXPIRED: 'Expired',
} as const;

export const VALUABLE_REQUIREMENT = {
  WAGER: 'wager',
  DEPOSIT: 'deposit',
  KAMBI_SPORTS_BET: 'kambiSportsBet',
} as const;

export const WAGERING_LOCK_AWARD_TYPE = {
  SPINS: 'spins',
  FREE_MONEY: 'freeMoney',
  BONUS_MONEY: 'bonusMoney',
} as const;

export const LOCK_TYPE = {
  WAGERING: 'wagering',
  DEPOSIT: 'deposit',
  GAME_WAGERING: 'gameWagering',
  GAME_CATEGORY_WAGERING: 'gameCategoryWagering',
  KAMBI_SPORTS_BET: 'kambiSportsBet',
  DEPOSIT_THEN_WAGER: 'depositThenWager',
} as const;

export const STATE_LOCKED = 'Locked';

export const API_TYPES = {
  ItemAwardingDepositBonusUsableUsedEvent: 'ItemAwardingDepositBonusUsableUsedEvent',
  WageringRequirementUsableUsedEvent: 'WageringRequirementUsableUsedEvent',
  BonusMoneyUsableUsedEvent: 'BonusMoneyUsableUsedEvent',
  FreeMoneyUsableUsedEvent: 'FreeMoneyUsableUsedEvent',
  FreeSpinsUsableUsedEvent: 'FreeSpinsUsableUsedEvent',
  SourceSpecificFreeSpinsUsableUsedEvent: 'SourceSpecificFreeSpinsUsableUsedEvent',
  DepositBonusUsableUsedEvent: 'DepositBonusUsableUsedEvent',
  SportsDepositBonusUsableUsedEvent: 'SportsDepositBonusUsableUsedEvent',
  FreeBetUsableUsedEvent: 'FreeBetUsableUsedEvent',
  FreeBetLockedUsableUsedEvent: 'FreeBetLockedUsableUsedEvent',
  LiveCasinoFreeBetUsableUsedEvent: 'LiveCasinoFreeBetUsableUsedEvent',
  CashbackUsableUsedEvent: 'CashbackUsableUsedEvent',
  ItemAwardingWageringLockUsableUsedEvent: 'ItemAwardingWageringLockUsableUsedEvent',
  CombinedChristmasItemUsedEvent: 'CombinedChristmasItemUsedEvent',
  CombinedChristmasItemUsableUsedEvent: 'CombinedChristmasItemUsableUsedEvent',
  ReelRaceInvitationUsedEvent: 'RaceInvitationUsableUsedEvent',
};

export const TYPES = {
  REAL_MONEY: 'REAL_MONEY',
  BONUS_MONEY: 'BONUS_MONEY',
  DEPOSIT_BONUS: 'DEPOSIT_BONUS',
  SPORTS_DEPOSIT_BONUS: 'SPORTS_DEPOSIT_BONUS',
  SIMPLE_FREE_SPINS: 'SIMPLE_FREE_SPINS',
  SIMPLE_BONUS_SPINS: 'SIMPLE_BONUS_SPINS',
  KAMBI_FREE_BET: 'KAMBI_FREE_BET',
  LIVE_CASINO_FREE_BET: 'LIVE_CASINO_FREE_BET',
  CASHBACK: 'CASHBACK',
  WAGERING_LOCK: 'WAGERING_LOCK',
  BUNDLE_LOCK: 'BUNDLE_LOCK',
  RR_INVITATION: 'RR_INVITATION',
  DEPOSIT_THEN_WAGER: 'DEPOSIT_THEN_WAGER',
};

export const VALUABLE_SUB_TYPES = {
  [TYPES.REAL_MONEY]: 'realMoney',
  [TYPES.BONUS_MONEY]: 'bonusMoney',
  [TYPES.DEPOSIT_BONUS]: 'depositBonus',
  [TYPES.SPORTS_DEPOSIT_BONUS]: 'sportsDepositBonus',
  [TYPES.SIMPLE_FREE_SPINS]: 'simpleFreeSpins',
  [TYPES.SIMPLE_BONUS_SPINS]: 'simpleBonusSpins',
  [TYPES.KAMBI_FREE_BET]: 'kambiFreeBet',
  [TYPES.LIVE_CASINO_FREE_BET]: 'liveCasinoFreeBet',
  [TYPES.CASHBACK]: 'cashback',
  [TYPES.WAGERING_LOCK]: 'wageringLock',
  [TYPES.BUNDLE_LOCK]: 'bundleLock',
  [TYPES.RR_INVITATION]: 'rrInvitation',
  [TYPES.DEPOSIT_THEN_WAGER]: 'depositThenWager',
} as const;

export const API_TYPES_TO_TYPES = {
  [API_TYPES.DepositBonusUsableUsedEvent]: TYPES.DEPOSIT_BONUS,
  [API_TYPES.BonusMoneyUsableUsedEvent]: TYPES.BONUS_MONEY,
  [API_TYPES.FreeMoneyUsableUsedEvent]: TYPES.REAL_MONEY,
  [API_TYPES.FreeSpinsUsableUsedEvent]: TYPES.SIMPLE_FREE_SPINS,
  [API_TYPES.SourceSpecificFreeSpinsUsableUsedEvent]: TYPES.SIMPLE_FREE_SPINS,
  [API_TYPES.ItemAwardingDepositBonusUsableUsedEvent]: TYPES.SIMPLE_BONUS_SPINS,
  [API_TYPES.SportsDepositBonusUsableUsedEvent]: TYPES.DEPOSIT_BONUS,
  [API_TYPES.FreeBetUsableUsedEvent]: TYPES.KAMBI_FREE_BET,
  [API_TYPES.FreeBetLockedUsableUsedEvent]: TYPES.KAMBI_FREE_BET,
  [API_TYPES.LiveCasinoFreeBetUsableUsedEvent]: TYPES.LIVE_CASINO_FREE_BET,
  [API_TYPES.CashbackUsableUsedEvent]: TYPES.CASHBACK,
  [API_TYPES.ItemAwardingWageringLockUsableUsedEvent]: TYPES.WAGERING_LOCK,
  [API_TYPES.CombinedChristmasItemUsedEvent]: TYPES.BUNDLE_LOCK,
  [API_TYPES.CombinedChristmasItemUsableUsedEvent]: TYPES.BUNDLE_LOCK,
  [API_TYPES.ReelRaceInvitationUsedEvent]: TYPES.RR_INVITATION,
};

export const VALUABLE_TYPE_TEMPLATE_MAP = {
  REAL_MONEY_SLUG: 'free-money-template',
  BONUS_MONEY_SLUG: 'bonus-money-template',
  FREE_SPINS_SLUG: 'free-spins-template',
  WAGER_FREE_SPINS_SLUG: 'wager-free-spins-template',
  CASHBACK_SLUG: 'cashback-template',
  CASHBACK_REWARD_SLUG: 'cashback-money-template',
  CASHBACK_ON_DEPOSIT_SLUG: 'cashback-on-deposit-template',
  FREE_SPINS_ON_DEPOSIT_BONUS_SLUG: 'free-spins-on-deposit-bonus-with-amount-locked-template',
  WAGER_FREE_SPINS_ON_DEPOSIT_BONUS_SLUG:
    'wager-free-spins-on-deposit-bonus-with-amount-locked-template',
  REAL_MONEY_ON_DEPOSIT_BONUS_SLUG: 'free-money-on-deposit-bonus-locked-template',
  BONUS_MONEY_ON_DEPOSIT_BONUS_SLUG: 'bonus-money-on-deposit-bonus-locked-template',
  DEPOSIT_BONUS_SLUG: 'deposit-bonus-template',
  DEPOSIT_BONUS_ON_DEPOSIT_BONUS_SLUG: 'deposit-bonus-on-deposit-bonus-template',
  DEPOSIT_BONUS_THEN_WAGER_SLUG: 'deposit-bonus-then-wager-template',
  REAL_MONEY_ON_WAGERING_SLUG: 'wagering-bonus-free-money',
  WAGER_FREE_SPINS_ON_WAGERING_SLUG: 'wagering-bonus-wager-free-spins',
  FREE_SPINS_ON_WAGERING_SLUG: 'wagering-bonus-free-spins',
  BONUS_MONEY_ON_WAGERING_SLUG: 'wagering-bonus-bonus-money',
  REAL_MONEY_ON_GAME_SPECIFIC_WAGERING_SLUG: 'wagering-bonus-game-specific-free-money',
  WAGER_FREE_SPINS_ON_GAME_SPECIFIC_WAGERING_SLUG: 'wagering-bonus-game-specific-wager-free-spins',
  FREE_SPINS_ON_GAME_SPECIFIC_WAGERING_SLUG: 'wagering-bonus-game-specific-free-spins',
  BONUS_MONEY_ON_GAME_SPECIFIC_WAGERING_SLUG: 'wagering-bonus-game-specific-bonus-money',
  REAL_MONEY_ON_GAME_CATEGORY_SPECIFIC_WAGERING_SLUG: 'wagering-bonus-vertical-specific-free-money',
  WAGER_FREE_SPINS_ON_GAME_CATEGORY_SPECIFIC_WAGERING_SLUG:
    'wagering-bonus-vertical-specific-wager-free-spins',
  FREE_SPINS_ON_GAME_CATEGORY_SPECIFIC_WAGERING_SLUG: 'wagering-bonus-vertical-specific-free-spins',
  BONUS_MONEY_ON_GAME_CATEGORY_SPECIFIC_WAGERING_SLUG:
    'wagering-bonus-vertical-specific-bonus-money',
  SPORTS_FREE_BET_SLUG: 'free-bet-template',
  SPORTS_FREE_BET_LOCKED_SLUG: 'free-bet-locked-template',
  SPORTS_PROFIT_BOOST_SLUG: 'profit-boost-template',
  SPORTS_PROFIT_BOOST_LOCKED_SLUG: 'profit-boost-locked-template',
  SPORTS_DEPOSIT_BONUS_SLUG: 'sports-deposit-bonus-template',
  LIVE_CASINO_FREE_BET_ITEM_SLUG: 'live-casino-free-bet-item-template',
  RACE_INVITATION_ITEM_SLUG: 'race-invitation-item-template',
};

export const KAMBI_OFFER_TYPES = {
  PROFIT_BOOST: 'kambiProfitBoost',
  FREE_BET: 'kambiFreeBet',
  CASHBACK: 'cashback',
} as const;
