export const NOTIFICATION_KIND = {
  TOAST: 'toast',
  POPUP: 'popup',
} as const;

export const NOTIFICATION_TYPE = {
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
  WARNING: 'warning',
} as const;

export const NOTIFICATION_STATUS = {
  READ: 'READ',
  UNREAD: 'UNREAD',
} as const;
