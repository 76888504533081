import { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import { gatekeeper } from 'Src/gatekeeper';
import { store, UserSlice } from 'Src/store';

type TProps = {
  children: React.ReactNode;
};

export const StoreProvider = ({ children }: TProps) => {
  const [loading, setLoading] = useState(true);
  const auth = gatekeeper.auth;
  const wallet = gatekeeper.wallet;

  useEffect(() => {
    (async () => {
      store.dispatch(
        UserSlice.actions.setPlayerInfo({
          playerInfo: {
            name: (await auth.getName()) as string,
            preferred_username: (await auth.getCasumoName()) as string,
            sid: (await auth.getSessionId()) as string,
            player_id: (await auth.getPlayerId()) as string,
            currency: (await wallet.getPlayerCurrency()) as string,
          },
        })
      );
    })()
      .catch(console.error.bind(console))
      .finally(() => {
        setLoading(false);
      });
  }, [auth, wallet]);

  if (loading) return null;

  return <Provider store={store}>{children}</Provider>;
};

export default StoreProvider;
