import { type TWalletInfo } from './walletBalance.types';
import { PlayerApi } from '../player/playerApi';

export const API_URL_GET_WALLET_INFO = '/seamlesswallet-transactions-query/api/v1/wallet';

export const PlayerWalletBalanceApi = PlayerApi.injectEndpoints({
  endpoints: (builder) => ({
    getWalletInfo: builder.query<TWalletInfo, void>({
      query: () => ({
        method: 'GET',
        url: API_URL_GET_WALLET_INFO,
      }),
    }),
  }),
  overrideExisting: true,
});

export const { useGetWalletInfoQuery } = PlayerWalletBalanceApi;
