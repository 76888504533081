import * as React from 'react';
import { type TFTMessage, useFTMessaging } from './useFTSDK';

type TFTSDKContextProviderProps = {
  children: React.ReactNode;
};

type TFTSDKContext = {
  messages: TFTMessage[];
  getUnread: () => Promise<{
    Data: TFTMessage[];
  }>;
  markAsRead: (id: string) => Promise<any>;
  deleteMessage: (id: string) => Promise<any>;
};

export const FTSDKContext = React.createContext<TFTSDKContext>({
  messages: [],
  getUnread: async () => await Promise.resolve({ Data: [] }),
  markAsRead: async (_: string) => await Promise.resolve(null),
  deleteMessage: async (_: string) => await Promise.resolve(null),
});

export const FTSDKContextProvider = ({ children }: TFTSDKContextProviderProps) => {
  const { messages, getUnread, markAsRead, deleteMessage } = useFTMessaging();

  return (
    <FTSDKContext.Provider
      value={{
        messages,
        getUnread,
        markAsRead,
        deleteMessage,
      }}
    >
      {children}
    </FTSDKContext.Provider>
  );
};

export const useFTSDKContext = () => {
  return React.useContext(FTSDKContext);
};
