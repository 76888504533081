import { useEffect, useState } from 'react';
import { type TQueryMatchFilters } from './useFetchRetentionContent';
import { useDirectusLocalizedQuery } from './useDirectus';
import type * as DirectusTypes from 'Src/directus.types';

type TFameFilter = {
  name: {
    [key in TQueryMatchFilters]?: string;
  };
};

const FAME_QUERY = `
  query Fame ($filter: fame_filter) {
    fame (filter: $filter) {
      name
      translations {
        content
        asset {
          id
        }
      }
    }
  }
`;

export const useFetchFameQuery = (filter: TFameFilter) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState<DirectusTypes.Fame[] | null>(null);
  const localizedQuery = useDirectusLocalizedQuery<'fame'>();
  useEffect(() => {
    let abort = false;
    (async function () {
      try {
        if (abort) return null;
        setIsError(false);
        setIsLoading(true);
        const data = await localizedQuery({
          query: FAME_QUERY,
          translationsNodeNames: ['fame'],
          variables: { filter },
        });
        if (!abort) setData(data?.fame);
        return null;
      } catch {
        if (!abort) setIsError(true);
      } finally {
        if (!abort) setIsLoading(false);
      }
      return null;
    })();
    return () => {
      abort = true;
    };
  }, [
    setIsLoading,
    setData,
    localizedQuery,
    filter.name._eq,
    filter.name._icontains,
    filter.name._istarts_with,
    setIsError,
  ]);

  return { data, isLoading, isError };
};
