const VALUABLE_QUERY_FIELDS = `
  id
  name
  translations {
    brief_content
    button_text
    content
    expiration_message
    label
    link_text
    lock_message
    priority_badge_text
    title
    caveat
  }
  is_locked_template
  compose_with_locked_template
  related_terms_and_conditions {
    terms_and_conditions_id {
      valuable_specific_terms
      translations {
        title
        content
      }
    }
  }
`;

export const RELATED_TERMS_AND_CONDITIONS_QUERY = `query RelatedTermsAndConditionsForValuable ($valuableId: string) {
  valuables_terms_and_conditions {
    valuables_id(filter: {id: { _eq: $valuableId}}) {
      related_terms_and_conditions {
        terms_and_conditions_id {
          translations {
            title
            content
          }
        }
      }
    }
  }
}`;

// todo: update to get by multiple slugs (array) using _in: [hnslug1, slug2]
export const VALUABLE_BY_NAME_QUERY = `query ValuableTemplatesByName ($names: [String]) {
  valuables(filter: {name: {_in: $names}}) {
    ${VALUABLE_QUERY_FIELDS}
  }
}`;

export const VALUABLE_BY_ID_QUERY = `query ValuableTemplatesById ($ids: [GraphQLStringOrFloat]) {
  valuables(filter: {id: {_in: $ids}}) {
    ${VALUABLE_QUERY_FIELDS}
  }
}`;
