import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type TRootState } from './store';

import { type TUserSlice, type TPlayerInfoPartial } from './types';

const initialState: TUserSlice = {
  playerInfo: undefined,
};

export const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setPlayerInfo: (state, { payload }: PayloadAction<{ playerInfo: TPlayerInfoPartial }>) => {
      const { playerInfo } = payload;
      state.playerInfo = playerInfo;
    },
  },
});

export const selectPlayerId = (state: TRootState) => state.user?.playerInfo?.player_id;
export const selectUserFullName = (state: TRootState) => state.user?.playerInfo?.name;
export const selectPreferredUserName = (state: TRootState) =>
  state.user?.playerInfo?.preferred_username;
export const selectPlayerCurrency = (state: TRootState) => state.user?.playerInfo?.currency;
