import { PlayerApi } from '../player/playerApi';
import { type TWarmUpDetailsResponse } from './playerWarmUpTypes';

export const playerWarmUpApi = PlayerApi.injectEndpoints({
  endpoints: (builder) => ({
    getWarmUpDetails: builder.query<TWarmUpDetailsResponse, void>({
      query: () => ({
        method: 'GET',
        url: '/player-warmup/api/warmup/details',
      }),
    }),
  }),
});

export const { useLazyGetWarmUpDetailsQuery } = playerWarmUpApi;
