import { isProdEnv } from './utils/tools';

export const ENVIRONMENT_URLS = {
  dev: 'localhost',
  test: 'casumotest.com',
  test_es: 'casumotest.es',
  stage: 'casumostage.com',
  prod: 'casumo.com',
  prod_es: 'casumo.es',
} as const;

export const DIRECTUS_BASE_URL_TEST = 'https://casumotest.directus.app';
export const DIRECTUS_BASE_URL_LIVE = 'https://casumo.directus.app';

export const DIRECTUS_IMGIX_URL_TEST = 'https://images-cdn.casumotest.com/casumotest';
export const DIRECTUS_IMGIX_URL_LIVE = 'https://images-cdn.casumo.com/casumo';

export const DIRECTUS_ASSET_TEST_URL = 'https://casumotest.directus.app/assets';
export const DIRECTUS_ASSET_PROD_URL = 'https://casumo.directus.app/assets';

export const PUBLIC_DIRECTUS_URL =
  process.env.REACT_APP_PUBLIC_DIRECTUS_URL ??
  (isProdEnv() ? DIRECTUS_BASE_URL_LIVE : DIRECTUS_BASE_URL_TEST);

export const PUBLIC_CMS_IMGIX_URL =
  process.env.PUBLIC_CMS_IMGIX_URL ??
  (isProdEnv() ? DIRECTUS_IMGIX_URL_LIVE : DIRECTUS_IMGIX_URL_TEST);

export const DIRECTUS_ASSET_URL =
  process.env.DIRECTUS_ASSET_TEST_URL ??
  (isProdEnv() ? DIRECTUS_ASSET_PROD_URL : DIRECTUS_ASSET_TEST_URL);

export const IS_STANDALONE = process.env.STANDALONE;

export const WEB_COMPONENT_TYPES: Record<string, string> = {
  NOTIFICATIONS: 'notifications',
  MARKETING_WIDGET: 'marketing-widget',
  QUICK_EMAIL_MARKETING_WIDGET: 'quick-email-marketing-widget',
  VALUABLE_LIST: 'valuables-list',
  ADVENTURE_DETAILS_WIDGET: 'adventure-details-widget',
  ADVENTURE_PROGRESS_BAR: 'adventure-progress-bar',
  ADVENTURE_CASUMO_AVATAR: 'adventure-casumo-avatar',
  ACHIEVEMENTS_LIST: 'achievements-list',
  HALL_OF_FAME_LIST: 'hall-of-fame-list',
  HERO_FAME: 'hero-fame',
  HALL_OF_FAME_GROUP_LIST: 'hall-of-fame-group-list',
  PROMOTION_INTRO_BLOCK: 'promotion-intro-block',
  PROMOTION_CONTENT_BLOCK: 'promotion-content-block',
  PROMOTIONS_PAGE: 'promotions-page',
  PROMOTIONS_CAROUSEL: 'promotions-carousel',
  REEL_RACE_PROMO_PAGE: 'reel-race-promo-page',
  REEL_RACE_CARD: 'reel-race-card',
  CURATED_CARD: 'curated-card',
  HERO_REEL_RACE: 'hero-reel-race',
} as const;

export const THUNDERBYTE_SESSION_MAPPING =
  '/casino-player/thunderbite-integration/api/v1/session-mapping';

export const PUSHER_CONSTANTS = {
  CONFIG_URL_STAGING: 'https://am-events-staging.fasttrack-solutions.com/api/v1/config/casumocrm',
  CONFIG_URL_PRODUCTION: 'https://am-events.fasttrack-solutions.com/api/v1/config/casumocrm',
  EXTERNAL_SESSION_URL: '/fasttrack-realtime-integration/api/v1/session-mapping',
  pusherChannelnamePrefix: 'private-prisma-223-',
  pusherEvents: [
    'pusher:subscription_succeeded',
    'crm_campaign',
    'system_alerts',
    'player_message',
    'fraud_kyc',
  ],
  PAGE_LOADED: '/fasttrack-realtime-integration/api/v1/page-load',
  pusherSubscriptionSuccessEvent: 'pusher:subscription_succeeded',
  backGroundImageColors: {
    top_card_pink:
      'https://cms.casumo.com/wp-content/uploads/2021/11/05-Modal-Artwork_Message-C.png',
    top_card_orange:
      'https://cms.casumo.com/wp-content/uploads/2021/11/07-Modal-Artwork_Message-D.png',
    top_card_purple:
      'https://cms.casumo.com/wp-content/uploads/2021/11/01-Modal-Artwork_Message-A.png',
    top_card_light_purple:
      'https://cms.casumo.com/wp-content/uploads/2021/11/03-Modal-Artwork_Message-B.png',
  },
} as const;

export const GAME_INFO_TOPIC = 'launch';

export const MARKETS = {
  ___en: '___en',
  ca_en: 'ca_en',
  fi_fi: 'fi_fi',
  gb_en: 'gb_en',
  in_en: 'in_en',
  no_no: 'no_no',
  on_en: 'on_en',
  se_sv: 'se_sv',
  es_es: 'es_es',
  nz_en: 'nz_en',
  jp_ja: 'jp_ja',
  ie_en: 'ie_en',
} as const;

export const CURRENCIES = {
  EUR: 'EUR',
  GBP: 'GBP',
  DKK: 'DKK',
  NOK: 'NOK',
  SEK: 'SEK',
  CAD: 'CAD',
  NZD: 'NZD',
  INR: 'INR',
  USD: 'USD',
  JPY: 'JPY',
} as const;

export const URL_PREFIXES = {
  [MARKETS.___en]: 'en',
  [MARKETS.ca_en]: 'en-ca',
  [MARKETS.fi_fi]: 'fi',
  [MARKETS.gb_en]: 'en-gb',
  [MARKETS.in_en]: 'en-in',
  [MARKETS.no_no]: 'no',
  [MARKETS.on_en]: 'on',
  [MARKETS.se_sv]: 'sv',
  [MARKETS.es_es]: 'es',
  [MARKETS.nz_en]: 'en-nz',
  [MARKETS.jp_ja]: 'ja',
  [MARKETS.ie_en]: 'ie',
} as const;

export const MARKET_SLUGS_FROM_SPINE = {
  es: 'es',
  en: 'en',
  'en-gb': 'en-gb',
  'en-ca': 'en-ca',
  'en-nz': 'en-nz',
  on: 'on',
  fi: 'fi',
  sv: 'sv',
  ie: 'ie',
  no: 'no',
  rog: 'rog',
  row: 'row',
} as const;

export const LOCALE_CODES = {
  en: 'en',
  'en-CA': 'en-CA',
  'de-DE': 'de-DE',
  'fi-FI': 'fi-FI',
  'en-GB': 'en-GB',
  'en-IN': 'en-IN',
  'no-NO': 'nb-NO',
  'sv-SE': 'sv-SE',
  'es-ES': 'es-ES',
  'en-NZ': 'en-NZ',
  'ja-JP': 'ja-JP',
  'de-AT': 'de-AT',
  'en-IE': 'en-IE',
} as const;

export const FALLBACK_EN_LOCALE = LOCALE_CODES.en;

export const DEVICES = {
  DESKTOP: 'desktop',
  MOBILE: 'mobile',
  UNKNOWN: 'unknown',
};

export const ROUTES = {
  PLAYER_DASHBOARD: 'PLAYER_DASHBOARD',
};
