import { PlayerApi } from '../player/playerApi';
import {
  type TPlayerMarketingStatusResponse,
  type TPlayerOptInMarketingArgs,
} from './marketingWidgetApi.types';

const API_URL_GET_MARKETING_STATUS = '/player-details/api/v1/player/contact-settings';
const API_URL_OPTIN_MARKETING = '/player/api/v1/player/newsletters/email';

export const MarketingWidgetApi = PlayerApi.injectEndpoints({
  endpoints: (builder) => ({
    getMarketingStatus: builder.query<TPlayerMarketingStatusResponse, void | null>({
      query: () => ({
        method: 'GET',
        url: API_URL_GET_MARKETING_STATUS,
      }),
    }),
    optInToMarketing: builder.mutation<any, TPlayerOptInMarketingArgs>({
      query: (body) => ({
        url: API_URL_OPTIN_MARKETING,
        method: 'PUT',
        body,
      }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useLazyGetMarketingStatusQuery,
  useGetMarketingStatusQuery,
  useOptInToMarketingMutation,
} = MarketingWidgetApi;
