import { PlayerApi } from '../player/playerApi';
import {
  type TGameListItem,
  type TGetGameListResponse,
  type TGetGameIdBySlugResponse,
} from './games.types';
import { transformGamesList } from './games.utils';

export const API_URL_GET_GAMES_BATCH_BY_SLUG = '/casino-games/api/v2/games/batch';
export const API_URL_GET_GAME_ID_BY_SLUG = '/casino-games/api/v2/mapping/slug';
export const API_URL_GET_GAME_LIST = '/casino-games/api/v2/gamelists';
export const API_URL_GET_GAME_BY_SLUG = '/casino-games/api/v2/games/slug';

export const GamesApi = PlayerApi.injectEndpoints({
  endpoints: (builder) => ({
    getGamesBySlugOrId: builder.query<
      TGetGameListResponse['games']['items'],
      { slugs?: string[]; ids?: string[] }
    >({
      query: ({ slugs, ids }) => ({
        method: 'POST',
        url: API_URL_GET_GAMES_BATCH_BY_SLUG,
        body: {
          slugs,
          ids,
        },
      }),
      transformResponse: (data: TGameListItem[], _meta, { slugs, ids }) => {
        if (slugs) {
          // sort the games by the order of the slugs
          return transformGamesList(data).sort(
            (x, y) => slugs.indexOf(x.slug) - slugs.indexOf(y.slug)
          );
        }
        // sort the games by the order of the ids
        if (ids) {
          return transformGamesList(data).sort((x, y) => ids.indexOf(x.id) - ids.indexOf(y.id));
        }

        return transformGamesList(data);
      },
    }),
    getGameIdBySlug: builder.query<TGetGameIdBySlugResponse, string>({
      query: (slug) => ({
        method: 'GET',
        url: `${API_URL_GET_GAME_ID_BY_SLUG}/${slug}`,
      }),
    }),
    getGameBySlug: builder.query<TGameListItem, string>({
      query: (slug) => ({
        method: 'GET',
        url: `${API_URL_GET_GAME_BY_SLUG}/${slug}`,
      }),
    }),
    getGameList: builder.query<TGetGameListResponse['games']['items'], string>({
      query: (slug: string) => ({
        method: 'GET',
        url: `${API_URL_GET_GAME_LIST}/${slug}`,
      }),
      transformResponse: (data: TGetGameListResponse) => {
        const {
          games: { items },
        } = data;

        return transformGamesList(items);
      },
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetGameListQuery,
  useGetGameIdBySlugQuery,
  useGetGamesBySlugOrIdQuery,
  useLazyGetGameListQuery,
  useLazyGetGameIdBySlugQuery,
  useLazyGetGameBySlugQuery,
  useLazyGetGamesBySlugOrIdQuery,
} = GamesApi;
