import React, { useContext } from 'react';
import { createIntl, createIntlCache, type IntlShape } from '@formatjs/intl';

export type Messages = Record<string, string>;

type TIntlBaseOptions = {
  locale: string;
  messages?: Messages;
};

type TIntlProviderProps = TIntlBaseOptions & {
  children: React.ReactNode;
};

const IntlContext = React.createContext<IntlShape | null>(null);

export const IntlProvider: React.FC<TIntlProviderProps> = ({
  locale = 'en',
  messages = {},
  children,
}: TIntlProviderProps) => {
  const cache = createIntlCache();
  const intl = createIntl({ locale, messages }, cache);

  return <IntlContext.Provider value={intl}>{children}</IntlContext.Provider>;
};

export const useIntl = (options?: Partial<TIntlBaseOptions>) => {
  const intlFromContext = useContext(IntlContext);

  if (options) {
    const { locale, messages } = options;
    const cache = createIntlCache();
    const localeCode = locale ?? (intlFromContext?.locale as string);
    return createIntl({ locale: localeCode, messages }, cache);
  }

  if (!intlFromContext) {
    throw new Error(
      'useIntl must be used within an IntlProvider or provided with a messages argument'
    );
  }

  return intlFromContext;
};

// todo: this throws an error https://github.com/formatjs/formatjs/blob/34634cec8d84c7cee4ba4e80e1384c758c67ede1/packages/intl/src/error.ts#L91
// whenever we call intl.formatMessage, due to IntlProvider not having defined messages at creation
// we can either create custom error handler to stop spamming the console - https://github.com/formatjs/formatjs/issues/465#issuecomment-568170768
// or stop using intl/formatjs altogether and use `@utils/interpolate` and `@interpolateAll`
export function interpolate<T extends Messages>(
  intl: IntlShape,
  messages: T,
  templateParameters: Record<string, string | number>
): T {
  const interpolatedMessages: Messages = {};

  Object.entries(messages).forEach(([id, defaultMessage = '']) => {
    const interpolatedMessage = intl.formatMessage({ id, defaultMessage }, templateParameters);

    // todo: check if we can replace with https://formatjs.io/docs/intl/#fallbackonemptystring by setting to false
    // Preserve unsupported placeholders by replacing them with the original placeholders
    const preservedMessage = Object.entries(templateParameters).reduce(
      (acc, [placeholder, value]) => {
        const placeholderRegex = new RegExp(`{${placeholder}}`, 'g');
        return acc.replace(placeholderRegex, value ? value.toString() : `{${placeholder}}`);
      },
      interpolatedMessage
    );

    interpolatedMessages[id] = preservedMessage;
  });

  return interpolatedMessages as T;
}
